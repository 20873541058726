import React, { Component } from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import { H1, MainButton as Button } from '../../buttons/styledComponents'
import Form from '../../forms/Feedback'
import withModal from '../../forms/withModal'

const ModalForm = withModal(Form)

const Container = styled.section`
  width: 688px;
  margin-top: 100px;
  @media (max-width: 1129px) {
    width: 70%;    
    margin-top: 150px;
  }
  @media (max-width: 991px) {
    width: auto;
    margin-top: 75px;
  }

`
const Title = styled(H1)`
  margin-bottom: 37px;
  @media (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 1rem;
  }
`
const Inner = styled.div`
  p {
    font-size: 18px;
    line-height: 1.5;
    color: #000000;
    margin-bottom: 40px;
    @media (max-width: 991px) {
      font-size: 14px;
      margin-bottom: 1.5rem;
    }
  }
`

export default class WriteToUsSingle extends Component {
    state = { showForm: false }

    showForm = () => {
        this.setState({ showForm: true })
    }
    closeForm = () => {
        this.setState({ showForm: false })
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                  query getWriteToUsSingle {
                    allMarkdownRemark (
                      filter: {
                        frontmatter: {section: {eq: "writeToUs"}}
                      }
                      sort: {fields: [frontmatter___main], order: ASC}
                    ) {
                      edges {
                        node {
                          frontmatter {
                            title
                          }
                          html
                        }
                      }
                    }
                  }
                `}
                render={data => {
                    //console.log(data);
                    const node = data.allMarkdownRemark.edges[0].node;
                    return (
                        <Container>
                            <Title>{node.frontmatter.title}</Title>
                            <Inner dangerouslySetInnerHTML={{ __html: node.html }} />
                            <Button onClick={this.showForm}>Написать нам</Button>
                            {this.state.showForm ? <ModalForm close={this.closeForm} /> : null}
                        </Container>
                    )
                }}
            />
        )
    }
}