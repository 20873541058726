import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

//import { H1 } from '../../buttons/styledComponents'
import { ProductsBlock } from '../../styledComponents/products'

const Container = styled.section`
  margin: 100px 0 100px;
  @media (max-width: 1129px) {
    margin-top: 100px;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }    

`

const ProductsSingle = () => {

    return (
        <StaticQuery
            query={graphql`
            query getProductsSingle {
                allMarkdownRemark (
                    filter: {
                        frontmatter: {section: {eq: "products"}}
                    }
                    sort: {fields: [frontmatter___main], order: ASC}
                    ) {
                edges {
                    node {
                    frontmatter {
                        title
                    }
                    html
                    }
                }
                }
            }
            `}
            render={data => {
                //console.log(data);
                const node = data.allMarkdownRemark.edges[0].node;
                return (
                    <Container>
                        <ProductsBlock dangerouslySetInnerHTML={{ __html: node.html }} />
                    </Container>        
                )
            }}
        />
    )
}

export default ProductsSingle;