import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layouts';
import { Container } from '../components/styledComponents';
import { Title } from '../components/styledComponents/products';
import Seo from '../components/seo/page';
import WriteToUsSingle from '../components/pages/main/WriteToUsSingle';
import ProductsSingle from '../components/pages/main/ProductsSingle';

export default class Contacts extends Component {

    componentDidMount() {
        document.body.style.background = "#fff"
        //console.log(this.props.data);
    }

    render() {

        const { data } = this.props;
        const node = data.allMarkdownRemark.edges[0].node;
        return (
            <Layout>
                <Seo title={node.frontmatter.title} meta={{
                    description: node.frontmatter.description,
                    keywords: node.frontmatter.keywords,
                    'og:url': 'products',
                    'og:description': node.frontmatter.og_description
                }} />
                <Container>
                    <Title>Продукты</Title>

                    <WriteToUsSingle />
                    <ProductsSingle />

                </Container>
            </Layout>
        )
    }
}

export const query = graphql`
  query getProductData {
    allMarkdownRemark (
      filter: {
        frontmatter: {page: {eq: "products"}}
      }
      sort: {fields: [frontmatter___main], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            page
            section
            title
            index
            main
            block
            keywords
            description
            og_description
          }
          html
        }
      }
    }
  }
` 